.my-account-page .card, .sales-history .card {
  border-radius: 1.2em;
}

.card-sale-details .lead {
  font-size: 20px;
}

.card-sale-details .card {
  height: 160px;
}

.card-sale-details .card-body .lead {
 margin-top: 10px;
}

.card-sale-details .font-weight-semibold {
  text-align: center;
}
