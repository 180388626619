.topup-option-container {
    border: 0.8px #8926ebb8 solid;
    margin-bottom: 5px;
}

.topup-option-heading {
    background-color: #8926ebb8;
    color: #fff;
    padding:10px;
    cursor: pointer;
   
} 

.topup-option-container form {
    padding: 10px;
}

.end-customer-show-all {
    text-align: center;
    border: 0.5px solid #3400a2;
    color: #3400a2;
    padding: 5px;
    border-radius: 50px;
    font-size: 12.8px;
    cursor: pointer;
}

.end-customer-show-all:hover, .end-customer-show-all.isactive {
    background-color: #3400a2;
    border-color: #3400a2;
    color: white;
}

