.banner-img{
    width: 398px;
    height: 398px;
    margin-bottom: 0px;
    /* border-radius: 2rem; */
}

.banner-heading{
    font-weight: 600;
    font-size: 32px;
    font-family: sans-serif;
    line-height: 1.33;
    color: rgb(255, 255, 255);
}

.banner-btn{
 border-radius: 0.5rem !important;
 font-size: 1.25rem !important;
 height: 45px;
 max-width: 306px;
}

.banner-main-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.banner-text-content{
  display: flex;
  flex-direction: column;
}

.banner-text-padding{
  padding-inline: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: block;
}

.banner-image{
  display: flex;
  justify-content: center;
  width: 100%;
}

.banner-img{
  width: 100%;
  height: 398px;
  margin-bottom: 0px;
  padding: 1rem
}

.banner-content {
  margin-top: 10px;
}

.banner-content p{
  font-size: 16px;
  margin-bottom: 10px !important;
  font-family: sans-serif;
}

@media (min-width: 992px) {
  .banner{
    padding-top: 47px;
    padding-bottom: 47px;
    padding-inline: 70px;
  }
  .banner-main-content{
    flex-direction: row;
  }

  .banner-text-content{
    max-width: 50%;
  }

  .banner-text-padding{
    align-items: center;
    justify-content: space-around;
  }

  .banner-image{
    max-width: 49.9%;
  }

  .banner-img{
    width: 398px;
    margin-bottom: 0px;
  }

  .banner-heading{
    font-size: 47px;
  }

  .banner-content{
    margin-top: 20px !important;
  }

  .banner-btn{
    margin-top: 40px;
  }
}

@media (min-width: 768px){
 .banner-heading{
    line-height: 1.2;
  }

  .banner-img{
    height: 398px;
  }
}

.preview-modal{
  max-width: 100% !important;
}