



.phome-number-list {
    font-size: 18px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .phome-number-list .badge {
    border: 1px solid #aca8a8;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
   
  }

  .phome-number-list .badge.phonenumani {
    animation: phonenumani 5s;
  }

  @keyframes phonenumani {
    0% { margin-left: -20px; opacity: 0; transform: translate(0,-40px,0) }
    25% { margin-left: -20px; opacity: 0.25; transform: translate(0,-30px,0) }
    50% { margin-left: 0; opacity: 0.5; transform: translate(0,-20px,0) }
    75% { margin-left: -20px; opacity:  0.75; transform: translate(0,-10px,0) }
    100% {  margin-left:  20px; opacity: 1; transform: translate(0,0px,0) }
  }

  .page-enter {
    animation: phonenumani 15s;
  }
  
  .phome-number-list .badge:hover, .phone-list-modal .search-label:hover  {
    background-color: #640dcf;
    color: #fff;
  }
  .phone-list-modal .search-label {
    margin-right: 5%;
    border: 1px solid #640dcf;
    padding: 5px 10px;
    border-radius: 1.5em;
    cursor: pointer;
  }

  .icon-rtl input.form-control{
    padding-right: 25px;
    background: url("../../assets/img/qr-code.png") no-repeat right;
    background-size: 20px;
  }

  .search_field {
    position: relative;
   
}

.search_field input.form-control {
   
  padding: 5px 5px;
}

.search_field button {
  content: "";
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  width: 30px;
  background: url("../../assets/img/qr-code.png") center / contain no-repeat;
}




  