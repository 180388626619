.from-div {
  height: 400px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 10px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.to-div {
  height: 400px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  /* padding: 20px; */
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 5px;
}
.rs-company-txt {
  white-space: nowrap; 
  width: 100px; 
  overflow: hidden;
  text-overflow: ellipsis
}
.lft-rt-arrow-div {
  position: absolute;
  top: 52%;
  left: 52%;
  transform: translate(-50%, -50%);
}

.label-ret-swap {
  line-height: 0;
  margin-top: 5px;
  margin-left: 3px;
}

.checkbox-div {
  margin-left: 20px;
  margin-top: 0px;
}

.lft-colm {
  position: relative;
}

.lft-rt-arrow-div {
  position: absolute;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
}

.rt-heading {
  font-size: 16px;
  font-weight: bold;
  margin-top: 50px;
}

.rt-content {
  padding: 5px;
}

.lt-heading {
  font-size: 16px;
  font-weight: bold;
}

.custom-panel .p-multiselect-panel .p-component .p-ripple-disabled .p-connected-overlay-enter-done {
  z-index: 1002;
  position: absolute;
  top: 0px !important;
  left: 448.8px;
  min-width: 468.4px;
  transform-origin: center top 0px;
}

.p-multiselect-panel {
  margin-top: -140px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
  overflow-x: auto;
}

.red-txt {
  color: #2c927a;
}

.rs-company-txt {
  white-space: nowrap; 
  width: 100px; 
  overflow: hidden;
  text-overflow: ellipsis
}

@media only screen and (max-width: 767px) {
  .p-multiselect-panel {
    margin-top: -110px;
  }

  .lft-rt-arrow-div {
    position: absolute;
    top: 52%;
    left: 52%;
    transform: translate(-50%, -50%);
  }

  .from-div {
    height: 400px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 10px;
    overflow-y: auto;
    margin-bottom: 50px;
  }

  .to-div {
    height: 400px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    /* padding: 20px; */
    overflow-y: auto;
    margin-top: 20px;
    padding: 5px;
  }

  .label-ret-swap {
    line-height: 0;
    margin-top: 0px;
    font-size: 12px;
    margin-left: 3px;
  }

  .checkbox-div {
    margin-left: 20px;
    margin-top: 0px;
  }

  .rt-heading {
    font-size: 15px;
    font-weight: bold;
    margin-top: 50px;
  }

  .lt-heading {
    font-size: 15px;
    font-weight: bold;
  }

  .list-cls {
    margin-left: 0px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .p-multiselect-panel {
    margin-top: -110px;
    width: 25%;
  }

  .lft-rt-arrow-div {
    position: absolute;
    top: 52%;
    left: 52%;
    transform: translate(-50%, -50%);
  }

  .label-ret-swap {
    line-height: 0;
    margin-top: 0px;
    font-size: 10px;
    margin-left: 3px;
  }

  .checkbox-div {
    margin-left: 3px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 1024px) {
  .lft-rt-arrow-div {
    position: absolute;
    top: 50%;
    left: 51.5%;
    transform: translate(-50%, -50%);
  }

  .p-multiselect-panel {
      margin-top: -110px;
  }
}

@media only screen and (max-width: 426px) {
  .p-multiselect-panel {
    margin-top: -80px;
  }
}

@media only screen and (max-width: 425px) {
  .p-multiselect-panel {
    margin-top: -80px;
  }
  .lft-rt-arrow-div {
    position: absolute;
    top: 52%;
    left: 52%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 320px) {
  .p-multiselect-panel {
    margin-top: -80px;
  }
  .lft-rt-arrow-div {
    position: absolute;
    top: 52%;
    left: 52%;
    transform: translate(-50%, -50%);
  }
  .checkbox-div {
    margin-left: 3px;
    margin-top: 0px;
  }
}
