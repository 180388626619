.width-for-small-card{
  max-width:12px;
  max-height:12px;
}

.height-of-menu{
  height:400px;
}

.Select-input {
  height: 300px;
}