.sim-search-table-styles {
            border-color: #7f1ce2;
            border-style: solid;
            border-radius: 5px;
            border-width: 2px;
            padding: 0px 0px 0px 0px !important;
          
    
}

.sim-search-tabs {
width: 33% !important;
padding: 0px 10px 0px 10px !important;
margin-bottom: 0px !important;
}

.sim-search {
    padding: 0px 0px 0px 0px !important;
    color: #fff !important;
    background-color: #7f1ce2 !important;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    display: block;
    line-height: 2.75em;
    height: 3em;
    padding: 0 1.618em;
    background: rgba(0, 0, 0, 0.1);
    border-right: 0.125rem solid #979b9a;
    align-content: center;
    cursor: pointer;
    top: 0;
    transition: all 0.25s;

}

.simsearchactive1 {
    /* height: 3em;
    position: relative;
    z-index: 1; */
    /* padding: 1.618rem; */
    color: #7f1ce2 !important;
        background-color: #ffff !important;
    transition: all 0.35s;
}

.sim-search-tab-border-color{
    background-color: #7f1ce2;
}

.sim-search-tab-content{
    height: none !important;
    position: none !important;
    z-index: none !important;
    top: none !important;
    left: none !important;
    padding: 1.618rem;
    background: #fff;
    color: #2c3e50;
    border-bottom: none !important;
    opacity: none !important;
    transition:none !important;
}

.nav-no-bottom{
        border-bottom: none !important;
}

@media (max-width: 768px) {
    .sim-search-tab-content {
        height: auto !important;
        position: static !important;
        z-index: unset !important;
        top: unset !important;
        left: unset !important;
        padding: 1.618rem;
        background: #fff;
        color: #2c3e50;
        border-bottom: none !important;
        opacity: 1 !important;
        transition: none !important;
    }
}

.msisdn-not-stock {
    /* color: #fff; */
    color: #fff !important;
    border-color: #dc3545 !important;
    background-color: #dc3545 !important;
}