.tag-item .search {
  border-bottom: none !important;
}

.infinite-scroll-component {
  height: 200px !important;
  overflow: auto;
}

.tag {
  border-radius: 2px;
  color: hsl(0, 0%, 20%);
  font-size: 85% !important;
  overflow: hidden;
  padding: 3px !important;
  padding-left: 6px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  background-color: #ffff !important;
}

.tag-remove {
  font-size: 15px !important;
  color: #000000 !important;
  font-weight: bold !important;
  padding: 1px 6px 1px 10px !important;
}

.tag-item {
  margin: 2px !important;
}

.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: 0.25rem 0.25rem;
  margin: 2px;
  background: #ffff;
  color: #495057;
  border-radius: 0px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 13px;
  font-weight: 500;
}

.p-treeselect-label {
  white-space: normal;
  height: 100px !important;
  overflow: auto !important;
}

.p-treeselect-panel .p-treeselect-header {
  display: none !important;
}

.p-treeselect {
  width: 100% !important;
}

.p-treeselect-items-wrapper {
  overflow: auto;
  max-height: 300px !important;
}

.p-connected-overlay-enter-done {
  top: 265px !important;
}

.p-dropdown-panel {
  top: 195px !important;
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #fff !important;
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled).p-focus {
  color: #495057;
  background: #fff !important;
}

.p-dropdown {
  width: 100% !important;
  border-radius: 0px !important;
}

.p-dropdown .p-dropdown-trigger {
  width: 2.1rem !important;
}

.p-icon {
  width: 0.6rem !important;
  height: 1rem;
}

.p-inputtext {
  padding: 0.45rem 0.75rem !important;
  color: hsl(0, 0%, 50%) !important;
  font-size: 14px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.5rem 1.25rem !important;
}