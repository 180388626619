.width-100-for-pins-delete{
  width:100%;
}

.admin-pins-delete-instruction {
  cursor: pointer;
  text-transform: uppercase;
  color: #007bff !important;
}

.quill-border .ql-toolbar.ql-snow {
  border-bottom: none !important;
  padding: 0px;
}