.heading {
  background-color: #7f1ce2 !important;
  color: white !important;
  border-radius: 5px 5px 5px 0px;
  padding: 5px 10px 0px 10px;
  width: 300px;
  margin-bottom: 0px !important;
}

.circle-icon-user {
  padding: 7px 10px;
  border-radius: 50%;
  border: 1px solid #9e4bdf;
  font-size: 16px;
  color: #9e4bdf;
  vertical-align: middle;
  margin-left: 10px;
}

.table-responsive table thead th {
  text-align: center;
}

.notification-modal i {
  font-size: 70px;
}

.optionname {
  margin-top: 50px;
}

.notification-modal i.simple-icon-check,
.success-text {
  color: #2c927a;
}

.notification-modal i.simple-icon-close {
  color: #922c2c;
}

.notification-modal i.simple-icon-exclamation {
  color: #ffc107;
}

.notification-modal .duplicate-pin-details {
  height: 100px;
  overflow-y: auto;
}

.international-phone-input.react-tel-input .form-control {
  width: 100% !important;
}

.international-topup-operator {
  width: 50px;
  height: 50px;
}

.international-topup-operator img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.international-topup-mobile-input-card {
  height: 167px;
}

.logo-single {
  width: 100% !important;
  height: 100px !important;
}

.alphabet-division {
  padding: 10px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
}

.highlight-alphabet-division {
  padding: 10px;
  cursor: pointer;
  /* color:#52125a; */
  background-color: #7f1ce2;
  color: white;
  text-decoration: underline;
  font-weight: 900;
  font-size: 18px;
}

.page-heading {
  background-color: #7f1ce2 !important;
  color: white !important;
  border-radius: 5px 5px 5px 0px;
  padding: 5px 10px;
  /* width: 300px; */
  margin-bottom: 0px !important;
  cursor: pointer;
}

.bgcardbaggroundcss {
  background-color: #7e787e;
}

.separator-line-color {
  border-bottom: 3px solid #7f1ce2 !important;
  margin-top: -1px;
}

.pagintaion-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 768px) {
  .invoice-padding {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 768px) {

  .optionname,
  .quantity {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .circle-icon-user {
    font-size: 12px;
  }

  .page-heading {
    margin-top: 1rem !important;
    border-bottom-left-radius: 5px;
  }

  .navbar .marquee-container {
    display: none !important;
  }
}



.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #7e787e;
}

.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 20px !important;
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
  top: 3.5px !important;
}

.react-table-pagination i {
  cursor: pointer;
  font-size: 16px;
}

.react-table-pagination i[disabled] {
  cursor: initial;
  opacity: 0.5;
}

.cursor-pointer {
  cursor: pointer;
}

.admin-container .page-heading {
  /* width: 400px; */
}

.td-link {
  color: #007bff !important;
}

.icon1 {
  font-size: 35 px;
}

.transform-rotate {
  transform: rotateZ(180deg);
}

.carousel-container {
  margin-right: 10% !important;
}

.account-holder-info {
  font-size: 12px;
  font-weight: bold !important;
  /* color: #52125a ; */
}

.navbar .navbar-left {
  flex-basis: 0 !important;
}

.navbar {
  flex-wrap: nowrap !important;
}

.customer-nav .navbar-right-menu {
  width: fit-content !important;
}

.navbar .marquee-container .overlay {
  height: auto !important;
}

.marquee-display-class .marquee-container {
  overflow-x: clip !important;
}

.marquee-display-class .marquee-container .overlay {
  height: auto !important;
  --gradient-color: none !important;
}

@media (min-width: 769px) {
  .marquee-display-class {
    display: none;
  }

  .marquee-card-image {
    width: 75px !important;
    height: 50px !important;
  }
}

.marquee-card-image {
  width: 45px;
  height: 30px;
}

.td-cell-cls {
  padding: 0.5rem 0.75rem !important;
  vertical-align: middle !important;
  width: fit-content !important;
}

.subject-overflow-cls {
  font-size: 16px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  width: 200px !important;
  margin: 0;
}

.p-overflow-cls {
  font-size: 13.6px;
  font-weight: 600;

  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.sticky-column {
  position: sticky;
  top: 0;
  background-color: white;
  color: black;
}

.table-responsive {
  margin-top: 20px;
  max-height: 500px;
  overflow-y: scroll;
  width: 100%;
}

.url-link {
  cursor: pointer;
  text-transform: uppercase;
  color: #007bff !important;
}

.topup-btn-radious-css {
  border-radius: 5px !important;
}


.user-log-tab {
  border: 0.5px solid #dfdfe1;
  padding: 10px;
  margin: 5px 0;
  font-size: 0.75rem;
}

.user-log-tab .changed-field {
  font-weight: bold;
}

.user-log-tab .user-log-profile p {
  font-size: 0.65rem !important;
}

.user-log-scroll {
  height: 300px;
  overflow-y: auto;
}

.mail-option-scroll {
  height: 350px;
  overflow-y: auto;
}

.topup-btn-radious-css {
  border-radius: 5px !important;
}


@media (min-width: 1820px) {
  .icon-card-width {
    flex: 0 0 25% !important;
    max-width: 19% !important;
  }
}

@media (max-width: 1820px) and (min-width: 1740px) {
  .icon-card-width {
    flex: 0 0 25% !important;
    max-width: 22% !important;
  }
}



@media (max-width: 991px) {
  .carousel-container {
    margin-right: 0 !important;
  }
}

@media (max-width: 850px) {
  .alphabet-division {
    padding: 5px;
    font-size: 8px;
  }

  .highlight-alphabet-division {
    padding: 5px;
    font-size: 10px;
  }
}

@media (max-width: 470px) {
  .alphabet-division {
    padding: 3px;
    font-size: 6px;
  }

  .highlight-alphabet-division {
    padding: 3px;

    font-size: 8px;
  }
}

@media (max-width: 576px) {
  .separator-line-color {
    display: none;
  }

  .admin-container .page-heading,
  .page-heading {
    margin-bottom: 2rem !important;
    width: 100%;
  }

  .de-lable-css {
    font-size: 11px !important;
  }
}

@media (max-width: 768px) {
  .international-topup-mobile-input-card {
    height: 210px;
  }
}

@media (max-width: 950px) and (min-width: 768px) {
  .de-lable-css {
    font-size: 11px !important;
  }
}