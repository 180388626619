.flix-bus-number {
    background-color: #7f1ce2!important;
    color: white !important;
    border-radius: 5px;
    padding: 5px 10px ;
    margin-bottom: 0px !important;
    cursor: pointer;
    font-weight: bold;
  }

  .flix-bus-checkout .back i {
    font-size: 10px;
  }

  .flix-bus-checkout .checkout-title {
    font-weight: bold;
    margin-bottom: 0;
    margin-left: 10px;
  }

  .flix-bus-checkout .timer {
    background-color: #fff;
    padding: 8px 5px;
    font-weight: bold;
    text-align: center;
    font-size: 14px;
  }

  .flixbus-luggage .luggage-img {
    height: 40px;
    width: 80px;
  }

  .flixbus-luggage .luggage-img img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .flix-bus-checkout .total-payment {
    margin: 10px 0;
  }
  .flix-bus-checkout .total-payment p {
    font-size: 20px;
  }

  .flix-bus-checkout .timer svg {
    height: 20px;
    width: 20px;
  }


.voucher-apply {
  color: #9e4bdf;
}