.sidebar .main-menu ul li.active:after {
  content: none !important;
}
.sidebar .main-menu ul li a {
  height: 90px !important;
  text-align: center;
  word-break: break-word; 
  
}
.sidebar [class^="iconsminds-"]:before, [class*=" iconsminds-"]:before {
  width: auto !important;
}

.sidebar .main-menu ul li.active {
  background-color:  #7f1ce2;
}

.sidebar .main-menu ul li.active a {
  color: #fff !important;
}
.navbar .navbar-logo {
  position: relative !important;
  margin: auto 5px !important;
}

.navbar .navbar-logo .logo {
  width: 183px !important;
  background-position: center center !important;
  background-size: contain !important;
}
@media (max-width: 768px) {
  .navbar .navbar-logo .logo, .navbar .navbar-logo .logo-mobile {
    width: 100px !important;
  }
  .navbar .menu-button-mobile {
    margin-left: 25px !important;
  }

  .container.main-for-login {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  * {
    box-sizing: border-box;
  }
  body {
    font-family: "Open Sans";
    background: #2c3e50;
    color: #ecf0f1;
    line-height: 1.618em;
  }
  .wrapper {
    max-width: 50rem;
    width: 100%;
    margin: 0 auto;
  }
  .tabs {
    position: relative;
    margin: 3rem 0;
    background: #1abc9c;
    height: 14.75rem;
  }
  .tabs::before,
  .tabs::after {
    content: "";
    display: table;
  }
  .tabs::after {
    clear: both;
  }
  .tab {
    float: left;
  }
  .tab-switch {
    display: none;
  }
  .tab-label {
    position: relative;
    display: block;
    line-height: 2.75em;
    height: 3em;
    padding: 0 1.618em;
    background: #1abc9c;
    border-right: 0.125rem solid #16a085;
    color: #fff;
    cursor: pointer;
    top: 0;
    transition: all 0.25s;
  }
  .tab-label:hover {
    top: -0.25rem;
    transition: top 0.25s;
  }
  .tab-content {
    height: 12rem;
    position: absolute;
    z-index: 1;
    top: 2.75em;
    left: 0;
    padding: 1.618rem;
    background: #fff;
    color: #2c3e50;
    border-bottom: 0.25rem solid #bdc3c7;
    opacity: 0;
    transition: all 0.35s;
  }
  .tab-switch:checked + .tab-label {
    background: #fff;
    color: #2c3e50;
    border-bottom: 0;
    border-right: 0.125rem solid #fff;
    transition: all 0.35s;
    z-index: 1;
    top: -0.0625rem;
  }
  .tab-switch:checked + label + .tab-content {
    z-index: 2;
    opacity: 1;
    transition: all 0.35s;
  }
}