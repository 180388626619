.online-cards-search {
  position: relative;
  width: 200px;
}

.online-cards-search input {
  border-radius: 1.25em;
  width: 100%;
 
}

.online-cards-search span {
  position: absolute;
  top: 8px;
  right: 10px;
}


.large-filter {
  font-size: 22px;
  cursor: pointer;
}
.online-cards-card {
  position: relative;
}

.online-cards-card .card {
  width: 150px !important;
  height: fit-content !important;
  background-color: #eae6ea;
}

.online-cards-card  .card-body {
  padding: 0.2rem !important;
 
}

.online-cards-card  .no-stock-div {
  position: absolute;
  top:-5px;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.online-cards-card  .no-stock-div img
{
  width: auto;
  display: inline-block;
}

.online-cards-card img, .online-cards-card .card, .online-card-filter .card {
  border-radius: 1.2em !important;
}
.online-cards-card .cards-img {
  width: 150px !important;
  height: 100px!important;
  position: relative;
}
.online-cards-card .cards-img img {
  object-fit: fill;
  height: 100%;
  width: 100%;
}

.online-cards-card .card + div {
  margin-bottom: initial !important;
}

.online-cards-card.no-stock-cards .card {
  opacity: 0.4;
}

.vr {
display: inline-block;
-webkit-align-self: stretch;
align-self: stretch;
width: 1px;
min-height: 1em;
background-color: currentColor;
opacity: .25;
}


.text-overflow-ellipsis {
  text-overflow: ellipsis;
  width: 150px !important;
  white-space:nowrap;
  overflow:hidden;
}

.online-card-filter .card-body .col-md-4:nth-child(2) {
  border-right: 1px solid #cecece !important;
  border-left: 1px solid #cecece !important;
}

.large-filter .filter-count {
  position: absolute;
  top: -9px;
  right: 15px;
  font-size: 10px;
  color: #fff;
  background-color: #9e4bdf;
  padding: 4px 8px;
  text-align: center;
  border-radius: 50%;
}

.cards-collapse {
  display:inline-block;
  float: right;
  margin-top: 7.5px;
  cursor: pointer;
  font-size: 20px;
}

.input-disabled1 {
 
  /* width: '20px'!important; */
  /* border:#52125a !important;
  outline: #52125a !important; */
  background-color: white !important;
}
.purchase-in-button {
  display: block;
  font-size: 20px;
  font-weight: 900;
  
}

.favourite-icon {
  height: 25px !important; 
  width: 25px !important;
  position: absolute; 
  bottom: 5px; 
  right: 5px;
  padding: 3px;
  background-color: #eae6ea;
  border: 1px solid #646064;
  
}
.small-image{
  object-fit: contain;
  width: 27px;
  height: 27px;
}

@media (max-width: 768px) {

  .online-card-filter .card-body .col-md-4:nth-child(2) {
    border: none !important;
  }
}