.nav-link.active {
  color: #fff !important;
  background-color: #7f1ce2 !important;
  border-radius: 50px !important;
}

.nav-link:hover {
  cursor: pointer;
}

.merge-center {
  text-align: center; /* Center the text within the cell */
}

/* Hide the duplicated cells and add space for merged cell */
.merge-center .hidden-cell {
  display: none;
}

/* Style the merged cell */
.merge-center .merged-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto; /* Let the height be determined by content */
}

.hover-on-top{
  cursor: pointer;
}

.hover-on-top-clear {
  padding-left: 0.75rem;
}

.api-details-form .card-header-tabs .active.nav-link {
  background-color: #fff !important;
  color: #000 !important;
}

.nav-link.active1 {
  height: 3em;
  position: relative;
  z-index: 1;

  padding: 1.618rem;
  color: #fff !important;
  background-color: #7f1ce2 !important;
  border-bottom: 0.25rem solid #7f1ce2 !important;
  transition: all 0.35s;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border: initial;
  color: #3400a2;
}


/* .nav-link:hover {
  top: -0.25rem;
  transition: top 0.25s;
} */

.mail-option-emails {
  font-size: 0.85rem !important;
}
