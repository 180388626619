.wizard-steps {
  margin:20px 10px 0px 10px;
  padding:0px;

  position: relative;
  clear:both;
  font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
}
.wizard-steps .step {
  position:relative;
}
.wizard-steps div {
  float: left; 
margin-left: -2px;   
   
}
/* = STEP NUMBERS
----------------------------*/
.wizard-steps span {
  display: block;
  float: left;
  font-size: 10px;
  text-align:center;
  width:15px;
  margin: 2px 5px 0px 0px;
  line-height:15px;
  color: #640dcf;
  background: #FFF;
  border: 2px solid #a4a3a3;
  -webkit-border-radius:10px;
  -moz-border-radius:10px;
  border-radius:10px;
}
/* = DEFAULT STEPS
----------------------------*/
.wizard-steps a {
  position:relative;
  display:block;
  height:24px;
  padding:0px 10px 0px 3px;
  float: left;
  font-size:20px;
  line-height:24px;
  color:#640dcf;
  background: #F0EEE3;
  text-decoration:none;
  text-shadow:1px 1px 1px rgba(255,255,255, 0.8);
}
.wizard-steps .a-before {
  width:0px;
  height:0px;
  border-top: 12px solid #F0EEE3;
  border-bottom: 12px solid #F0EEE3;
  border-left:12px solid transparent;

}
.wizard-steps .a-after {
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left:12px solid #F0EEE3;
margin-left: 0;
}

/* = COMPLETED STEPS
----------------------------*/

.wizard-steps .completed-step a {
  color:#640dcf;
  background: #A3C1C9;
}
.wizard-steps .completed-step .a-before {
  border-top: 12px solid #A3C1C9;
  border-bottom: 12px solid #A3C1C9;
}
.wizard-steps .completed-step .a-after {
  border-left: 12px solid #A3C1C9;
}
.wizard-steps .completed-step span {
  border: 2px solid #640dcf;
  color: #640dcf;
  text-shadow:none;
}
/* = ACTIVE STEPS
----------------------------*/
.wizard-steps .active-step a {
  color:#f0f5f7;
  background: #640dcf;
  text-shadow:1px 1px 1px rgba(0,0,0, 0.8);
}
.wizard-steps .active-step .a-before {
  border-top: 12px solid #640dcf;
  border-bottom: 12px solid #640dcf;
}
.wizard-steps .active-step .a-after {
  border-left: 12px solid #640dcf;
}
.wizard-steps .active-step span {
  color: #640dcf;
  -webkit-box-shadow:0px 0px 2px rgba(0,0,0, 0.8);
  -moz-box-shadow:0px 0px 2px rgba(0,0,0, 0.8);
  box-shadow:0px 0px 2px rgba(0,0,0, 0.8);
  text-shadow:none;
  border: 2px solid #A3C1C9;
}
/* = HOVER STATES
----------------------------*/
.wizard-steps .completed-step:hover a, .wizard-steps .active-step:hover a {
  color:#fff;
  background: #8F061E;
  text-shadow:1px 1px 1px rgba(0,0,0, 0.8);
}
.wizard-steps .completed-step:hover span, .wizard-steps .active-step:hover span {
  color:#8F061E;
}
.wizard-steps .completed-step:hover .a-before, .wizard-steps .active-step:hover .a-before {
  border-top: 12px solid #8F061E;
  border-bottom: 12px solid #8F061E;
}
.wizard-steps .completed-step:hover .a-after, .wizard-steps .active-step:hover .a-after {
  border-left: 12px solid #8F061E;
}