.count {
    font-size: 9px;
    color: white;
    border: 1px solid #9e4bdf;
    background-color: #9e4bdf;
    border-radius: 10px;
    position: absolute;
    width: 18px;
    height: 15px;
    text-align: center;
    font-weight: 700;
    top: 22px;
    right: 5px;
    line-height: 14px;
  }

.header-icon {
    font-size: 16px;
    color: #8f8f8f;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    vertical-align: initial !important;
}

.margin-top-9{
  margin-top: 9px;
}

.margin-top-42px{
  margin-top:4px;
}

.margin-top-8{
  margin-top:8px;
}

.margin-top-20px{
  margin-top:20px;
}