.width-for-card-details {
  width: 40px;
}

.width-for-card-details-2 {
  max-width: 100px;
  height: 20px;
  margin-right: 20px;
}

.bold {
  font-weight: bold;
}
.home-cards {
  border-radius: 0.75rem;
}
.card-header-css {
  background-color: #7f1ce2 !important;
  color: white !important;
}


.list-icon-color{
  font-weight: bolder;

  color: #7f1ce2 !important;
}

.elementor-icon-list-items {
  -moz-column-count: 2;
  -moz-column-gap: 20px;
  -webkit-column-count: 2;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 20px;
}

@media  (min-width: 1900px) {
  .dashboard-admin {
    margin-left: auto;
    margin-right: auto;
    flex: 0 0 24% !important;
    max-width: 24% !important;
  }
}

@media (max-width: 1900px) and (min-width: 1500px) {
  .dashboard-admin {
    margin-left: auto;
    margin-right: auto;
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
}

@media (max-width: 1500px) and (min-width: 1200px) {
  .dashboard-admin {
    flex: 0 0 33.333% !important;
    max-width: 33.3333% !important;
  }
}

@media (max-width: 1050px) and (min-width: 992px) {
  .dashboard-admin {
    flex: 0 0 33.33% !important;
    max-width: 33.33% !important;
  }
}
@media (max-width: 576px) and (min-width: 420px) {
  .dashboard-admin {
    margin-left: auto;
    margin-right: auto;
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
}



.instruction-heading {
  background-color: #7f1ce2 !important;
  color: white !important;
  border-radius: 5px 5px 5px 5px;
  padding: 5px 10px;
  width: 230px;
  margin-bottom: 0px !important;
  cursor: pointer;
}

.small-image-width-height {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.input-disabled {
  color: black !important;
  /* border:#52125a !important;
  outline: #52125a !important; */
  background-color: white !important;
}

.table-for-pop-up {
  border: 1px;
}

.div-border {
  border: 1px;
  border-color: #9e4bdf;
  border-style: solid;
}

/* .horizontal-for-hr-tag {
  width:600px;
} */

/* table, th, td {
  border: 1px solid black;
} */

.online-card-details .input-group div {
  width: 20px;
  height: 40px;
  margin: 0 2px;
}

.online-card-details .input-disabled {
  width: 30px;
}

.purchase-in-button {
  display: block;
}
.purchase-out-button {
  display: none;
}

.online-card-details .accordion-content {
  white-space: pre-wrap;
  font-size: 13px;
  line-height: 1.5;
}

.online-card-details .glyph-icon {
  margin-top: 4px;
  font-size: 14px;
}

.pin-trash {
  display: none;
}

.quantity-cls {
  cursor: pointer;
  text-transform: capitalize;
  color: #007bff !important;
}

@media (max-width: 425px) {
  .purchase-in-button {
    display: none;
  }
  .purchase-out-button {
    display: block;
  }
}
