.direct-reacharge-card .card {
  height: 200px !important;
 border-radius: 1.2em;
 width: 200px !important;
}
.direct-reacharge-card .card-body {
  position: relative;
}

.direct-reacharge-card .card p {
  font-size: 18px;
}

.direct-reacharge-card .card img {
  height: 100px !important;
  max-width: 100%;
  max-height: 100%;
  object-fit: fill;
  margin-bottom: 20px;
}

.direct-recharge-selected-item  .card {
  /* width: 400px; */
  margin: auto;
}

.direct-recharge-selected-item .card-body .card-body {
   padding-top: 0 !important;
}

.direct-recharge-selected-item .selected-item-img  img {
  height: 100px;
  width: 150px;
}

.direct-recharge-selected-item .card .instruction {
  font-size: 0.9rem !important;
}

@media (max-width: 425px) {
  .direct-recharge-selected-item  .card {
      width: auto;
      
  }

}