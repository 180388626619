.image-for-home-page{
    width:30px;
    height:30px;
}

.div5 {
    flex-basis: 40%;
    min-height: 100px;
    max-height: 250px;
  }

.div6 {
  flex-basis: 40%;
  min-height: 100px;
  max-height: 150px;
}
  .div6 {
    flex-basis: 40%;
    min-height: 100px;
    max-height: 100%;
  }

.tab-space-for-home-pages {
tab-size: 8;
}

.text-size-for-home-pages {
font-size: 16px;
}

.diclaimer-text{
  font-size:14px;
  resize: none;
}

.font-for-instructions {
  font-size: 14px;
}